import React, { createContext, useState, useEffect } from "react";

import en from "./translations/en.json";
import fr from "./translations/fr.json";

// Available languages
const LANGUAGES = { en, fr };

// Default language
const DEFAULT_LANGUAGE = "en";

// Create Context
export const TranslationContext = createContext();

export const TranslationProvider = ({ children }) => {
	const [language, setLanguage] = useState(DEFAULT_LANGUAGE);
	const [translations, setTranslations] = useState(
		LANGUAGES[DEFAULT_LANGUAGE]
	);

	// Get system language from browser
	const getSystemLanguage = () => {
		const locale = navigator.language || navigator.userLanguage;
		return locale.toLowerCase().startsWith("fr") ? "fr" : "en";
	};

	// Load language from localStorage or use system default
	useEffect(() => {
		const storedLanguage = localStorage.getItem("selectedLanguage");
		const lang = storedLanguage || getSystemLanguage();
		setLanguage(lang);
		setTranslations(LANGUAGES[lang]);
	}, []);

	// Change language and save to localStorage
	const changeLanguage = (lang) => {
		const langCode = lang.toLowerCase();
		if (LANGUAGES[langCode]) {
			setLanguage(langCode);
			setTranslations(LANGUAGES[langCode]);
			localStorage.setItem("selectedLanguage", langCode);
		}
	};

	return (
		<TranslationContext.Provider
			value={{ language, translations, changeLanguage }}
		>
			{children}
		</TranslationContext.Provider>
	);
};
