import React from "react";
import {
	RouterProvider,
	createBrowserRouter,
	Navigate,
} from "react-router-dom";
import { initializeApp } from "firebase/app";
import { FirebaseProvider } from "./core/FirebaseProvider/FirebaseProvider";
import { AuthProvider } from "./core/AuthProvider/AuthProvider";
import { VfxProvider } from "./core/VfxProvider";
import { TranslationProvider } from "./core/TranslationProvider";

const Grantee = React.lazy(() => import("./pages/Grantee"));
const GranteeAccount = React.lazy(() => import("./pages/GranteeAccount"));
const GranteeAccountKYC = React.lazy(() =>
	import("./pages/GranteeAccount/kyc")
);

const GranteeAccountDev = React.lazy(() => import("./pages/GranteeAccountDev"));
const GranteeAccountKYCDev = React.lazy(() =>
	import("./pages/GranteeAccountDev/kyc")
);

const ExternalRedirect = () => {
	window.location.replace("https://grantsolutions.fr");
	return <></>;
};

const routes = createBrowserRouter([
	{
		path: "/grantee/:userCode/:success?",
		element: <Grantee />,
	},
	{
		path: "/grantee-account",
		element: <GranteeAccount />,
	},
	{
		path: "/grantee-account-dev",
		element: <GranteeAccountDev />,
	},
	{
		path: "/grantee-account-kyc",
		element: <GranteeAccountKYC />,
	},
	{
		path: "/grantee-account-kyc-dev",
		element: <GranteeAccountKYCDev />,
	},
	{
		path: "*",
		element: <ExternalRedirect />,
	},
]);

function App() {
	return (
		<VfxProvider>
			<TranslationProvider>
				<FirebaseProvider>
					<AuthProvider>
						<React.Suspense
							fallback={() => (
								<div
									className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
									role="status"
								>
									<span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
										Loading...
									</span>
								</div>
							)}
						>
							<RouterProvider router={routes} />
						</React.Suspense>
					</AuthProvider>
				</FirebaseProvider>
			</TranslationProvider>
		</VfxProvider>
	);
}

export default App;
